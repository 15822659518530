<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5 mb-3">Create District</span>
				</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-select dense clearable label="Area*" :menu-props="{top: false, offsetY: true}" :items="areas" :rules="rules.area" :error-messages="errors.area" v-model="object.area"></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense label="District Name*" hint="Eg: Orchard" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="create">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

export default {
	name: 'DistrictCreateDialog',
    props: {
        areas: {
            type: Array,
            required: false,
            default: () => []
        }
    },
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                name: '',
                area: ''
			},
			rules: {
                name: [
                    (value) => !!value || 'District name is required',
                    (value) => value && value.length >= 2 && value.length <= 100 || 'District name must be between 2 and 100 characters'
                ],
                area: [
                    (value) => !!value || 'Area is required',
                ],
			},
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
            this.loading = false
            this.object = { name: '', area: '' }
            this.errors = {}
			this.form.resetValidation()
		},
		create: function() {
			if(this.form.validate()){
				this.loading = true
                this.$store.dispatch('epanel/setting/createDistrict', this.object).then((response) => {
                    this.close()
                    this.$emit('created', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		}
	}
}

</script>